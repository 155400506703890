/*
=============== 
Fonts
===============
*/
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap"); */

/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-black: #000000;
  --clr-primary-1: #2a2a2a;
  --clr-primary-2: #3e3e3e;
  --clr-primary-3: #b9b9b9;
  --clr-white: rgba(255, 255, 255, 0.8);
  --ff-primary: 'Quattrocento Sans', sans-serif;
  --ff-secondary: 'Montserrat', sans-serif;
  --transition: all 0.2s linear;
  --spacing: 0.15rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 700px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--ff-primary);
  background: var(--clr-white);
  color: var(--clr-primary-1);
  line-height: 1.5;
  font-size: 0.875rem;
  margin-top: 3.5rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--clr-primary-1);
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.1rem;
}
p {
  font-size: 1.05rem;
  margin-bottom: 1.25rem;
  color: var(--clr-primary-1);
  line-height: 1.8;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  font-weight: bold;
  background: var(--clr-primary-5);
  color: var(--clr-primary-9);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  /* box-shadow: 0 1px 3px rgba(126, 126, 126, 0.2); */
  border-radius: var(--radius);
}
.btn:hover {
  font-weight: bold;
  color: var(--clr-primary-2);
  background: var(--clr-primary-8);
  transform: translateY(-3px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.center-btn {
  display: block;
  width: 12rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
}
/* section */
.section {
  padding: 3rem 0;
}

@media (max-width: 765px) {
  .section {
    padding: 2.5rem 0;
  }
}

@media (max-width: 625px) {
  .section {
    padding: 2rem 0;
  }
}

@media (max-width: 400px) {
  .section {
    padding: 1.5rem 0;
  }
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
/* added during recording */
.underline {
  width: 5rem;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.section-title {
  margin-bottom: 4rem;
  text-align: center;
}
