.news-banner {
  background-image: url('https://res.cloudinary.com/di1olybhs/image/upload/c_fill,h_700,w_1500/v1651402803/IMG_0016_socoh1.jpg');
}

@media (max-width: 720px) {
  .news-banner {
    background-position: 65% 40%;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-attachment: scroll;
  }
}

.news-container {
  height: 950px;
}
