.navbar {
  position: absolute;
  width: 100%;
  top: 9rem;
  height: 3rem;
  z-index: 99;
}

@media only screen and (max-width: 765px) {
  .navbar {
    display: none;
  }
}

@media only screen and (min-width: 766px) {
  .toggle-btn {
    display: none;
  }
}

.toggle-btn {
  position: fixed;
  top: 20px;
  right: 15px;
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  z-index: 99;
}

.toggle-btn:hover {
  color: var(--clr-primary-3);
}

.navbar-active {
  position: fixed;
  top: 0;
  width: 100%;
  height: 3rem;
  background: rgb(252, 252, 252);
  box-shadow: 0 0.3px 2px hsla(0, 1%, 61%, 0.524);
  opacity: 0.9;
}

.nav-wrap {
  margin: 0px auto;
  max-width: 600px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
}

.menu-item {
  font-family: var(--ff-secondary);
  list-style: none;
  display: block;
  padding-top: 10px;
  color: var(--clr-primary-3);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-size: 14px;
}

.menu-item:visited {
  color: var(--clr-primary-3);
}

.menu-item:hover {
  color: var(--clr-primary-2);
}

.activated {
  /* background-color: rgb(238, 238, 238); */
  border-bottom: 1px solid var(var(--clr-primary-3));
}
