.footer {
  background-image: linear-gradient(
      to bottom,
      rgba(67, 3, 32, 0.5),
      rgba(20, 19, 19, 0.5)
    ),
    url('https://res.cloudinary.com/di1olybhs/image/upload/c_fill,h_400,w_1450/v1651141921/morgan-vander-hart-Xq0dpzgSw_U-unsplash_id8blj.jpg');
  background-attachment: fixed;
  height: 9rem;
  /* background: #6c6c6c; */
  text-align: center;
  display: grid;
  place-items: center;
}
.footer h4 {
  /* margin-top: 0.25rem; */
  color: white;
  font-family: var(--ff-secondary);
  font-weight: lighter;
  font-size: 0.7rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.footer-links {
  margin: 1.25rem auto 0.2rem auto;
}
.footer .social-link {
  color: var(--clr-white);
}
.footer .social-link:hover {
  color: var(--clr-primary-3);
}

.bibo {
  color: #fff;
}

.bibo:hover {
  color: #e9b949;
}
