.cento-header {
  position: relative;
  width: 100%;
  background: #ffffff;
  margin-top: 40px;
  /* padding: 40px 0 0; */
  box-sizing: border-box;
}

.container {
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

@media (max-width: 765px) {
  .container {
    max-width: 600px;
  }
}

@media (max-width: 625px) {
  .container {
    max-width: 500px;
  }
}

@media (max-width: 400px) {
  .container {
    max-width: 100%;
  }
}

.logo {
  display: block;
  color: var(--clr-primary-1);
  text-align: center;
  letter-spacing: 0.04em;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  max-width: 700px;
}
@media (max-width: 765px) {
  .logo {
    font-size: 30px;
  }
}

@media (max-width: 625px) {
  .logo {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .logo {
    font-size: 25px;
    letter-spacing: 0.01em;
  }
}

/* .cento-header .logo {
  margin-bottom: 20px;
} */

a {
  color: var(--clr-primary-1);
  text-decoration: none;
  transition: all 300ms ease;
}
