.banner {
  width: 100%;
  height: 300px;
  line-height: 300px;
  text-align: center;
  background-attachment: fixed;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 3rem;
  font-size: 6rem;
  font-family: "Whisper", cursive;
  letter-spacing: 6px;
}

.about-banner {
  background-image: url("https://res.cloudinary.com/di1olybhs/image/upload/c_fill,h_700,w_1450,x_0,y_337/v1651193557/Lovepik_com-400245141-green-plant-cellphone-wallpaper_hb_y1aijy.jpg");
}

@media (max-width: 765px) {
  .banner {
    margin-top: 0px;
    height: 200px;
    line-height: 200px;
    font-size: 3rem;
  }
}

.content-container {
  width: 80%;
  max-width: 650px;
  margin: 0 auto;
}

.section-content {
  margin-bottom: 2.5rem;
}

.section-content h4 {
  color: rgb(69, 112, 96);
}

.section-content p {
  color: var(--clr-primary-2);
}

.about-text {
  margin-bottom: 15px;
}
