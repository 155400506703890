/* 
-----
sidebar
----- 
*/

.sidebar {
  background: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  opacity: 0;
  transition: all 0.2s linear;
  transform: translateX(100%);
  z-index: 99;
}

.show-sidebar {
  opacity: 1;
  transform: translateX(0);
}
.sidebar-links li {
  opacity: 0;
}
.sidebar-links a {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--ff-secondary);
  color: var(--clr-primary-3);
  letter-spacing: 3px;
  margin: 2rem;
  font-size: 1.3rem;
  transition: all 0.2s linear;
}
.sidebar-links a:hover {
  color: var(--clr-primary-2);
}
.sidebar-links .activated {
  background-color: rgb(234, 232, 232);
  border: none;
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.close-btn:hover {
  color: var(--clr-primary-3);
}

@media screen and (min-width: 992px) {
  .sidebar {
    transform: translateX(-100%);
  }
}

/*
=============== 
Sidebar Animation
===============
*/
.sidebar-links li {
  animation: slideRight 0.5s ease-in-out 0.3s forwards;
}
.sidebar-links li:nth-of-type(1) {
  animation-delay: 0.25s;
}
.sidebar-links li:nth-of-type(2) {
  animation-delay: 0.5s;
}
.sidebar-links li:nth-of-type(3) {
  animation-delay: 0.75s;
}
.sidebar-links li:nth-of-type(4) {
  animation-delay: 1s;
}
.sidebar-links li:nth-of-type(5) {
  animation-delay: 1.25s;
}
@keyframes slideRight {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.sidebar-icons li {
  opacity: 0;
  animation: slideUp 0.5s ease-in-out 0.3s forwards;
}
.sidebar-icons li:nth-of-type(1) {
  animation-delay: 0.25s;
}
.sidebar-icons li:nth-of-type(2) {
  animation-delay: 0.5s;
}
.sidebar-icons li:nth-of-type(3) {
  animation-delay: 0.75s;
}
.sidebar-icons li:nth-of-type(4) {
  animation-delay: 1s;
}
.sidebar-icons li:nth-of-type(5) {
  animation-delay: 1.25s;
}
@keyframes slideUp {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
