.sub-title {
  display: flex;
  font-weight: bold;
  justify-content: center;
  font-size: 19px;
  color: var(--clr-primary-1);
  margin-top: 1rem;
  font-family: var(--ff-secondary);
}

@media (max-width: 765px) {
  .sub-title {
    margin-top: -20px;
  }
}

.hero-img {
  width: 100%;
  padding-bottom: 0;
}

.photo-credit {
  color: var(--clr-primary-3);
}

a.photo-credit:hover {
  color: var(--clr-primary-2);
}

.heroImg-container {
  overflow: hidden;
  /* height: 791px; */
  margin: 0 auto;
}

@media (max-width: 765px) {
  .heroImg-container {
    /* height: 678px; */
  }
}

@media (max-width: 625px) {
  .heroImg-container {
    /* height: 565px; */
  }
}

@media (max-width: 414px) {
  .heroImg-container {
    height: 100%;
  }
}

@media (max-width: 400px) {
  .heroImg-container {
    /* max-height: 440px; */
  }
}
