.contact-banner {
  background-image: url('https://res.cloudinary.com/di1olybhs/image/upload/c_fill,h_700,w_1450/v1651194110/mykyta-martynenko-Dj8idk9Iu8A-unsplash_zzeg1v.jpg');
}

.contact-container {
  width: 80%;
  max-width: 250px;
  margin: 0 auto;
}

.contact-container a {
  font-size: 1.2rem;
  color: var(--clr-primary-2);
}

.contact-container a:hover {
  color: var(--clr-primary-3);
}
