.social-links {
  padding-left: 0;
  list-style: none;
  margin: 0rem auto;
  width: 9rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  color: var(--clr-primary-3);
  transition: 3s ease-in-out;
  cursor: pointer;
}
.social-link:hover {
  color: var(--clr-primary-2);
}

.herder-links a {
  color: var(--clr-primary-3);
}

.sidebar-icons a {
  color: var(--clr-primary-3);
}
